// i18next-extract-mark-ns-start capabilities-combustion-synthetic

import { LINKS, COMBUSTION_NAV, CAPABILITIES_PRODUCTS_COMBUSTION_NAV } from '@utils/constants';
import { graphql } from 'gatsby';
import React from 'react';

import Aside from '@components/Aside';
import AssetList from '@components/AssetList';
import Banner from '@components/Banner';
import Breadcrumbs, { Breadcrumb } from '@components/Breadcrumbs';
import PageSection from '@components/PageSection';
import Page from '@components/layout/Page';
import SEO from '@components/layout/SEO';
import { Link, Trans, useTranslation } from 'gatsby-plugin-react-i18next';

const CapabilitiesSyntheticCombustionPage = ({
	pageContext: {
		breadcrumb: { crumbs },
	},
	data
}: {
	pageContext: {
		breadcrumb: { crumbs: Breadcrumb[] };
	};
	data: any;
}) => {
	const { t } = useTranslation(); return (
		<Page>
			<SEO
				title={t("seo_title")}
				description={t("seo_description")}
			/>

			<Banner
				title={t("title")}
				image={{
					src: data.banner.childImageSharp.gatsbyImageData,
					alt: '',
				}}
				size="standard"
			/>

			<Breadcrumbs crumbs={crumbs} />

			<PageSection
				className="section__content section__content--dual"
				layout="none">
				<div className="col xl7 m12 m-spacer page__content page__content--dual">
					<Trans i18nKey="content">
						<h2>Simulating synthetic and biofuel combustion</h2>

						<p>
							Cleaner combustion within Internal Combustion Engines (ICEs) can be driven not only by the green non-carbon
							fuels but also by sustainable hydrocarbons which also offer a route towards a more environmentally responsible
							and energy efficient landscape.
						</p>
						<p>
							By utilizing renewable feedstocks, advanced production processes, and carbon capture technologies,
							sustainable hydrocarbon fuels provide an eco-friendly alternative to traditional fossil fuels.
							They can also operate as drop-in alternative in the existing powertrains which is advantageous from both the
							sustainability and efficiency standpoint.
						</p>
						<p>
							Optimal application of sustainable biofuels requires adjustment of
							existing ICE designs, while e-fuels, such as synthetic gasoline for example,
							open a new dimension in fuel formulation due to the finer control over fuel composition
							and wide variation in composition depending on the production method.
						</p>
						<p>
							Simulating combustion behaviour provides fast, accurate answers to the ‘What ifs’ such as:
							How to adapt an existing powertrain to perform best with Hydrotreated Vegetable Oil or Dimethyl-Ether?
							How does the composition of gasoline affect mixture quality, injection, and ignition processes?
							How to best calibrate a power-plant for different composition of gasoline and different ethanol content?
						</p>
						<p>
							Realis Simulation products provide flexible fuel definition and combustion and kinetics modelling approaches
							allowing our customers to explore the sustainable hydrocarbons alternatives and take advantage
							of their properties in ICE development.
						</p>
					</Trans>
				</div>
				<Aside>
					<Aside.Widget
						title={t("_CleanCombustion")}
						className="widget__sibling__pages">
						<AssetList>
							{COMBUSTION_NAV.map((item, i) => (
								<Link
									key={i}
									to={item.value}
									className="assets__link"
									activeClassName="active">
									{t(item.labelkey) /* i18next-extract-disable-line */}
								</Link>
							))}
						</AssetList>
					</Aside.Widget>
					<Aside.Widget
						title={t("_Products")}
						className="widget__sibling__pages">
						<AssetList>
							{CAPABILITIES_PRODUCTS_COMBUSTION_NAV.map((item, i) => (
								<Link
									key={i}
									to={item.value}
									className="assets__link"
									activeClassName="active">
									{t(item.labelkey) /* i18next-extract-disable-line */}
								</Link>
							))}
						</AssetList>
					</Aside.Widget>
					<Link
						to={LINKS.ABOUT_CONTACT}
						className="button--more">
						{t("_GetInTouch")}
					</Link>
				</Aside>
			</PageSection>
		</Page>
	);
};

export default CapabilitiesSyntheticCombustionPage;

export const pageQuery = graphql`
query ($language: String!) {
	locales: allLocale(
		filter: { ns: { in: ["capabilities-combustion-synthetic", "_common"] }, language: { eq: $language } }
	) {
		edges {
			node {
				ns
				data
				language
			}
		}
	}
	banner: file(relativePath: { eq: "content/capabilities/combustion/synthetic-and-biofuel/Realis-Simulation_1440_biofuel.jpg" }) {
		...imageBreaker
	}
}
`;
